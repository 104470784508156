.shimmer-wrapper {
    position: relative;
    overflow: hidden;
    height: 200px;
  }
  
  .shimmer-line {
    height: 30px;
    background: #e0e0e0;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }
  
  .shimmer-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  