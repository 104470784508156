

.hero-section-container .overlay {
    background-color: #eff8ff;
    background-repeat: no-repeat;
    background-position: top 100px right;
    position: relative;
    background-size: auto;
    background-attachment: fixed;
    padding: 40px 0 320px;
}

.hero-section-container .overlay .hero-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-heading {
    color: var(--primary-color);
    margin-bottom: 30px;
    font-size: 24px;
}

.top-section-heading {
    font-weight: 700;
    margin-bottom: 22px;
}

.ft55 {
    font-size: 55px !important;
}

.hero-section-content {
    margin-bottom: 34px;
}

.sub-heading {
    color: var(--themeht-primary-color);
    margin-bottom: 30px;
    font-size: 24px;
}

.Blue_Heading {
    color: var(--themeht-primary-color);
    font-weight: 600;
    text-transform: none;
    transition: all .3s;
}

.bg_blue {
    border-color: var(--themeht-primary-color);
    color: #ffffff;
}

.cmnFtLh {
    line-height: 36px;
    margin-top: -7px;
}

.glowshdo5 {
    box-shadow: 4px 3px 20px #00000026;
}

.roundwrapper {
    position: relative;
    background: #ffffff;
    border-radius: 20px;
}


.nav button.active {
    color: #fff;
    border-radius: 8px;
    background-color: var(--themeht-primary-color);
}

/* .sec-nav a:before {
    content: "\uF234";
    display: block;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 20px;
}  */

.btn_color {
    background-color: var(--themeht-primary-color);
}

.tgreyteel {
    color: gray;
}

.bar_blue {
    background-color: rgb(0, 115, 207);
    width: 32px;
    height: 4px;
    border-radius: 4px;
}
.cardNumber{
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.modal-open{
    padding-right: 0px !important;
}
.card_removeBtn .btn{
    background-color: var(--themeht-primary-color);
    padding:12px 16px 11px;
    border: 1px solid #00327e;
    color: #fff;
}
.green_bar {
    background-color: rgb(36, 161, 0);
    width: 32px;
    height: 4px;
    border-radius: 4px;
}

.bg_blue {
    background-color: var(--themeht-primary-color);
}

.outline_color {
    border-color: var(--themeht-primary-color);
    color: var(--themeht-primary-color);
}

.text_sm {
    font-size: 12px;
}

 .text_blue {
    color: var(--themeht-primary-color) !important;
}

.blue_active a:not(.know-more-link).active {
    color: var(--themeht-primary-color);
    font-weight: 600;
}

.blue_active a:not(.know-more-link) {
    margin-right: 20px;
    padding: 10px 0;
    position: relative;
    color: #404040;
}

.bg_bluelight {
    background-color: #eff8ff;
}

.popoverblock {
    width: 350px;
    left: -15px;
}
.w100{
    width: 100%;
}

.border_dashed{
    border-bottom: 1px  dashed rgb(229, 227, 227);
    
}
.modal-body::-webkit-scrollbar {
    width: 3px;
  }
   
  .modal-body::-webkit-scrollbar-track {
    background: #ffffff;
    box-shadow: inset 0 0 8px #f7f7f7;
    border-radius: 0;

  }
   .recharge_box p,  .primary_number p{
    font-size: 16px; 
    font-weight: 500;
   }
   .primary_number span{
    font-size: 13px;
    color:gray;
    
   }
   #Connection .btn-primary{
    background-color: var(--themeht-primary-color);
    padding:12px 16px 11px;
    border: 1px solid #00327e;
    color: #fff;
   }
  .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d8d8d8
  }
  /* PaymentCheck Css */

  .Payment_card{
    padding: 20px 35px 35px;
    box-shadow: #0000003d 0 3px 8px;
    background-color: #fff;
    border-radius: 20px;
    line-height: 30px;
  }
  .payment_checkout{
    margin-top: 89px;
    padding: 62px;
    background-color: #eff8ff;
  }
  .payment_checkout h3{
    font-size: 22px;
    font-weight: 600;
    color: var(--themeht-primary-color);
  }
  .Payment_card button.nav-link.active:after, .Payment_card button.nav-link:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-radius: 3px;
    height: 4px;
    background-color: var(--themeht-primary-color);
    content: "";
    opacity: 0;
    transition: opacity .3s, transform .3s;
    -o-transition: opacity .3s, -o-transform .3s;
    -ms-transition: opacity .3s, -ms-transform .3s;
    transform: translateY(-10px);
}
.Payment_card .nav.nav-tabs{
    position: relative;
}
/* .Payment_card button.nav-link:hover:after {
    opacity: 1;
    transform: translateY(0);
} */
.Payment_card button.nav-link.active:after {
    opacity: 1;
    transform: translateY(0);
}
.Payment_card .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link{
    position: relative;
}
.Payment_card .nav-tabs .nav-link.active{
    border-color: transparent !important;
}
.Payment_card .nav-tabs .nav-link:hover{
    border-color: transparent !important;
}
.Payment_card .nav-link{
    color: #000;
}
.Payment_card .tab-content{
    margin-top: 40px;
}
.debit_card h4{
    color: #000;
    font-size: 22px;
    font-weight: 600;
}
.Payment_card .cvv-img {
    display: flex;
    align-items: center;
}

.Payment_card .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0 10px;
}
.Payment_card .input-group-text img{
    position: relative;
    right: 39px;
}
.Payment_card .input-group input{
    padding-right: 37px;
}
.Payment_card label{
    font-weight: 400;
    color: var(--themeht-primary-color);
    font-size: 14px;
}
.payment_summary{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffff;
    border-radius: 20px;
    padding: 30px !important;
    padding-bottom: 0px !important;
    width: 500px;
    margin-top: 40px;
}
.payment_summary h3{
    font-weight: 600;
    font-size: 17px;
    color: #000;
}
.payment_summary p{
    font-size: 16px;
    font-weight: 400;
}
.final-Payment{
    padding: 20px 20px;
    background-color: #d3d3d3 !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -30px;
    margin-right: -30px;

}
.final-Payment p{
    font-size: 18px;
    font-weight: 600;
}