.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:9999;
  }
  .popup-content {
    background: white;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 100%;
}
  
  .close-btn {
    background: none;
    border: none;
    position: absolute !important;
    right: 24px;
    top: 8px;
    padding: 0;
    color: gray;
    font-size: 33px;
    font-weight: 300;
  }
  
  .close-btn:hover {
    color: red;
  }
  