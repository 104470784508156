/* General Styles */
.receipt-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .receipt-card {
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Header Styles */
  .receipt-header {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .receipt-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .receipt-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .receipt-header-right {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: right;
  }
  
  .receipt-pg-assured-logo {
    width: 160px;
    height: auto;
  }
  
  .receipt-date-info {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Company Info Styles */
  .receipt-company-info {
    margin-bottom: 20px;
    font-size: 0.9rem;
    color: #555;
    display: flex;
    justify-content: space-between;
  }
  
  /* Payment Details Styles */
  .receipt-details {
    margin-bottom: 20px;
  }
  
  .receipt-payment-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .receipt-payment-info div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .receipt-payment-info h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .receipt-payment-info p {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Table Styles */
  .receipt-table-responsive {
    overflow-x: auto;
  }
  
  .receipt-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .receipt-table th,
  .receipt-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .receipt-table th {
    font-weight: bold;
    background-color: #f9f9f9;
  }
  
  .receipt-table .receipt-text-end {
    text-align: right;
  }
  
  .receipt-total-row {
    font-weight: bold;
    background-color: #f9f9f9;
  }
  
  /* Footer Styles */
  .receipt-footer {
    margin-top: 20px;
    font-size: 0.8rem;
    color: #777;
    text-align: center;
  }
  
  /* Print Button Styles */
  .receipt-print-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .receipt-print-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .receipt-print-button:hover {
    background-color: #0056b3;
  }
  
  /* Media Queries for Responsiveness */
  @media (min-width: 768px) {
    .receipt-container {
      padding: 40px;
    }
  
    .receipt-header-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .receipt-payment-info {
      flex-direction: row;
      gap: 20px;
    }
  }
  
  @media (min-width: 1024px) {
    .receipt-container {
      max-width: 1024px;
      margin: 0 auto;
      margin-top: 80px;
    }
  }
  
  /* Print Styles */
  @media print {
    body * {
      visibility: hidden; /* Hide everything on the page */
    }
  
    #receipt-content,
    #receipt-content * {
      visibility: visible; /* Show only the receipt content */
    }
  
    #receipt-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border: none;
      box-shadow: none;
    }
  
    /* Ensure the logo is positioned correctly */
    .receipt-header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .receipt-header-right {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }
  
    .receipt-pg-assured-logo {
      width: 80px; /* Adjust size for print if needed */
      height: auto;
    }
  
    .receipt-print-button-container {
      display: none; /* Hide the print button */
    }
  }