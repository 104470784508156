.maindiv {
  width: 80vw;
  height: 80vh;
  background-color: lightpink;
  margin: 0 auto;
  border-radius: 15px;
}

.imagediv {
  border-radius: 15px;
  height: 80vh;
  width: 80vw;
}

/* .loginimage{
   height:100px;
   width: 100px;
}

.inputMaindiv{
  background-color: ;

} */
.bg-img {
  background: url(/public/assets//login.jpg);
  background-size: cover;
  background-blend-mode: screen;
  padding: 0 50px;
}

.login-content h1 {
  line-height: 24px;
  font-size: 24px;
  color: #fff;
}

.login-content p {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;
}

.login-content {
  max-width: 280px;
  text-align: left;
}

.logo-img {
  width: max-content;
}

.center-div {
  display: flex;
  justify-content: center;
  padding: 20px 40px;
  flex-direction: column;
  align-items: center;
}

.login-content ul {
  list-style-type: none;
  padding: 0;
}

.login-content ul li {
  font-size: 16px;
  font-weight: 400;
  color: #fff;

}

.login-content ul li i {
  color: #37b34a;
}

.QrCode_section {
  margin-top: 55px;

}

.QrCode_section .QrCode {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  background-color: #872d67;
  border-radius: 10px;
  padding: 15px;
}
.btn:hover, .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  background-color: #872d67d1;
  border-color: #872d67d1;
}
.QrCode_section .QrCode p {
  color: #fff;
  font-size: 14px;
}
.padding-md{
  padding:56px 80px 25px;
  background: #ffffff;
  border-radius:0px 30px 30px 0px;
  box-shadow: #959da533 0 8px 24px;
}
.login-Heading{
  text-align: left;
}
.login-Heading h2{
  font-weight: 500;
  color: #000;
  font-size: 28px;
  margin-bottom: 30px;
}
.login-Heading .form-label{
  color: var(--themeht-primary-color);
}
.login-Heading input{
  border-color: var(--themeht-primary-color);
  font-size: 14px;
}
.login-Heading .form-check a{
  color: var(--themeht-primary-color);
}
.login-Heading .form-check label {
  font-size: 16px;
    font-weight: 400;
}
.OtpBtn{
  box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f;
  background-color: var(--themeht-primary-color);
  border: 0px;
  color: #fff;
  margin-top:27px;
  border-radius: 8px;
  display: block;
}
.bgImage{
  left: 0;
  width: fit-content;
  z-index: 1;
}
.login-form-wrapper{
  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.login-form-wrapper .main-section{
  min-height: calc(100% - 215px);
  border-radius: 30px;
  margin: 0 auto;
}
.already-account h3{
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin: 0 auto;
}
.already-account button{
  color: var(--themeht-primary-color);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 0 auto;
}
.popup-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-contents {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 50%;

}
.popup-contents h3{
  color: #000;
  font-weight: 500;
}

@media (max-width: 768px) {
  .padding-md {
    padding: 16px;
    border-radius: 30px !important;

  }
  .login-form-wrapper{
    height: inherit;
  }
}