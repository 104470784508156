@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
body {

  background-color: #f5f9ff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  color: gray;

}

.navbar-item {
  font-size: 14px;
  font-weight: 500;
}

button {
  border-radius: 4px;
}


:root {
  --themeht-primary-color: #3498db; /* Example color */
  /* You can replace #3498db with any color code of your choice */
}


  /* Apply to all scrollbars on the page */
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar (horizontal) */
  }

  ::-webkit-scrollbar-track {
    background: #f4f4f4; /* Background color of the scrollbar track */
    border-radius: 6px; /* Rounded corners */

  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners */
    border: 3px solid #f4f4f4; /* Space around the thumb */
    
  }


  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }

  /* Optional: Adjust for Firefox */
  * {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #BA4680 #f4f4f4; /* Thumb color and track color */
  }