@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&amp;display=swap'); :root {
    --themeht-primary-color: #872D67;
    /* --themeht-primary-color: #c146f7; */
    /* --themeht-secondary-color: #2a4172; */
    --themeht-secondary-color: #14263F;
    --themeht-primary2-color: #103ce7;
    --themeht-secondary2-color: #64e9ff;
    --themeht-text-color: #191919;
    --themeht-white-color: #ffffff;
    --themeht-body-color: #606060;
    --themeht-bg-dark-color: #000229; 
    --themeht-bg-light-color: #f7f9fe;
    --themeht-border-light-color: #e1e1e1;
    --themeht-body-font-family: "Hanken Grotesk", sans-serif;
}
body{
    font-family: var(--themeht-body-font-family) !important;
}
.page-wrapper {
    overflow-x: hidden;
}
/* ------------------------
    Helper class
------------------------*/

section {
    padding: 50px 0 !important;
    position: relative;
}

.rounded {
    border-radius: 20px !important;
}


/* ------------------------
    Text color
------------------------*/
.text-theme {
    color: var(--themeht-primary-color);
}

.text-black {
    color: var(--themeht-text-color) !important;
}

.text-grey {
    color: var(--themeht-body-color);
}

/* ------------------------
    Background color
------------------------*/
.white-bg {
    background-color: var(--themeht-white-color) !important;
}

.dark-bg {
    background-color: #000229;
}

.light-bg {
    background-color: var(--themeht-bg-light-color);
}

.primary-bg {
    background-color: var(--themeht-primary-color);
}

.primary-bg2 {
    background-color: var(--themeht-primary2-color);
}

.secondary-bg {
    background-color: var(--themeht-secondary-color);
}

.secondary-bg2 {
    background-color: var(--themeht-secondary2-color);
}

.transparent-bg {
    background-color: inherit !important;
}

.grediant-bg {
    background-image: linear-gradient(180deg,#f6f5fa .01%,rgba(246,245,250,0))
}

.grediant-bg2 {
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
}

.grediant-bg3 {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
}

.border-light {
    border-color: var(--themeht-border-light-color) !important
}

/* ------------------------
    Banner
------------------------*/
[data-bg-img] {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.banner {
    padding: 98px 0 50px !important;
    position: relative;
}

.banner h1 span {
    font-weight: 700;
    font-size: 120px;
    line-height: 1;
    text-transform: uppercase;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1800px) {
    .banner .banner-img {
        margin-right: -100px;
    }
}

.banner-2 {
    padding: 220px 0 200px;
    position: relative;
    z-index: 9
}

.banner-2 h6 {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    color: var(--themeht-white-color);
    display: inline-block;
    border-radius: 100px;
    padding: 8px 20px;
}

.banner-3 {
    padding: 220px 0 500px;
}

.banner-3 h1, .banner-3 p {
    color: var(--themeht-white-color)
}

.banner-4 {
    padding: 250px 0 200px;
}

.banner h6 {
    display: inline-block;
    border: 1px solid var(--themeht-primary2-color);
    padding: 10px 20px;
    transform: rotate(-10deg);
    margin: 3px;
    position: relative;
    line-height: 1;
}

.banner h6 span {
    width: 6px;
    height: 6px;
    background: var(--themeht-primary-color);
    display: inline-block;
    position: absolute;
    top: -3px;
    left: -3px;
}

.banner h6 span:nth-child(2) {
    left: inherit;
    right: -3px;
    background: var(--themeht-secondary2-color);
}

.banner h6 span:nth-child(3) {
    top: inherit;
    bottom: -3px;
    background: var(--themeht-primary2-color);
}

.banner h6 span:nth-child(4) {
    top: inherit;
    bottom: -3px;
    left: inherit;
    right: -3px;
    background: var(--themeht-secondary-color);
}

/* -------------------------------
   Background Position & Size
-------------------------------*/
.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-pos-l {
    background-position: left;
}

.bg-pos-r {
    background-position: right;
}

.bg-pos-rt {
    background-position: right top;
}

.bg-pos-lt {
    background-position: left top;
}

.bg-pos-rb {
    background-position: right bottom;
}

.bg-pos-lb {
    background-position: left bottom;
}

/* ------------------------
    Clients Logo
------------------------*/
.clients-logo {
    text-align: center;
}

.clients-logo img {
    -webkit-filter: contrast(0%);
    filter: contrast(0%);
    opacity: .7;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
}

.clients-logo:hover img {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}

.client-text {
    text-align: center;
    position: relative;
}

.client-text:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    background: var(--themeht-border-light-color);
    width: 70%;
    height: 1px;
}

.client-text span {
    background: var(--themeht-white-color);
    padding: 5px 40px;
}

/* ------------------------
    Countdown
------------------------*/
.countdown {
    margin: 50px 0;
    padding: 50px 0;
    display: table;
    width: 100%;
    border-top: 2px dashed var(--themeht-border-light-color);
    border-bottom: 2px dashed var(--themeht-border-light-color);
}

.countdown > li {
    font-family: var(--themeht-secondary-font-family);
    font-weight: 700;
    text-align: center;
    list-style-type: none;
    display: table-cell;
    width: auto;
}

.countdown > li span {
    font-size: 70px;
    font-weight: 700;
    line-height: 60px;
    display: inline-block;
}

.countdown > li p {
    font-size: 20px;
    display: inline-block;
    line-height: 30px;
    margin-bottom: 0;
    color: var(--themeht-text-color);
}

.coming-soon .subscribe-form {
    margin: 0 auto;
}

/* ------------------------
    Error
------------------------*/
.error-path {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: dash 5s linear alternate infinite;
}

.error-path path {
    stroke: var(--themeht-primary-color)
}

.error-path path:nth-child(2) {
    stroke: var(--themeht-primary2-color)
}

.error-path path:nth-child(3) {
    stroke: var(--themeht-secondary2-color)
}

/* ------------------------
    Timeline
------------------------*/
.main-timeline:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: var(--themeht-border-light-color);
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
}

.timeline_item {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.timeline-order-1 {
    order: 3;
}

.timeline-order-2 {
    order: 2;
    left: 20px;
}

.timeline-order-3 {
    order: 1;
}

.main-timeline .timeline-icon {
    width: 40px;
    height: 40px;
    position: relative;
    right: 20px;
    font-size: 30px;
    background: var(--themeht-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--themeht-primary-color);
    border-radius: 50%;
    z-index: 9;
}

.main-timeline .date-content {
    width: 50%;
    position: relative;
}

.main-timeline .date-content:before {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--themeht-border-light-color);
    margin: auto 0;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
}

.main-timeline .timeline-order-1:before {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--themeht-border-light-color);
    position: absolute;
    padding-right: 30px;
    margin-right: 50% !important;
}

.main-timeline .date {
    font-family: var(--themeht-secondary-font-family);
    display: flex;
    line-height: 1;
    font-weight: 700;
    font-size: 30px;
    color: var(--themeht-white-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    align-items: center;
    justify-content: center;
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    border-radius: 50%;
    width: 125px;
    height: 125px;
    z-index: 1;
}

.main-timeline .timeline-content {
    padding: 20px 20px;
}

@media (max-width: 640px) {
    .timeline {
        flex-direction: column !important;
    }

    .main-timeline:before {
        margin-left: 1px;
    }

    .main-timeline .timeline-icon {
        margin: auto 2px;
    }

    .main-timeline .timeline-content {
        padding: 40px 30px;
    }

    .timeline-order-1 {
        order: 2;
    }

    .timeline-order-2 {
        order: 1;
        left: -1em;
    }

    .timeline-order-3 {
        order: 3;
    }

    .timeline-order-1b {
        order: 2;
    }

    .timeline-order-2b {
        order: 1;
        left: -1em;
    }

    .timeline-order-3b {
        order: 3;
    }

    .main-timeline .timeline-order-1:before {
        width: 100%;
    }

    .main-timeline .timeline-order-1b:before {
        width: 100%;
    }

    .main-timeline .timeline-order-1b:before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--themeht-border-light-color);
        position: absolute;
        top: 0;
        padding-right: 30px;
        bottom: 0;
        margin-right: 50% !important;
    }

    .main-timeline .date {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 350px) {
    .timeline-icon {
        visibility: hidden;
    }

    .main-timeline .date-content:before {
        visibility: hidden;
    }

    .date-content {
        left: -15px;
    }
}

/* ------------------------
    Terms and Conditions
------------------------*/
.terms-and-conditions p {
    margin-bottom: 25px;
}

.terms-and-conditions ul.list {
    margin-bottom: 20px;
    display: block;
}

.terms-and-conditions ul.list li i {
    padding-right: 12px;
    font-size: 15px;
}

/* ------------------------
    Privacy Policy
------------------------*/
.privacy-policy p {
    margin-bottom: 25px;
}

.privacy-policy ul.list {
    margin-bottom: 20px;
    display: block;
}

.privacy-policy ul.list li i {
    padding-right: 12px;
    font-size: 15px;
}

/* ------------------------
    Contact
------------------------*/
.form-group {
    margin-bottom: 20px;
    position: relative;
}

label {
    font-size: 14px;
    line-height: 1;
    color: var(--themeht-text-color)
}

.form-control {
    border-radius: 10px;
    font-size: 14px;
    color: var(--themeht-text-color);
    background-color: var(--themeht-bg-light-color);
    border: 1px solid #aaa;
}

textarea.form-control {
    height: 150px;
    border-radius: 10px;
}

.form-control:focus {
    box-shadow: none;
    background: none;
    border-color: var(--themeht-primary-color);
}

select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 50px !important;
    color: var(--themeht-body-color);
}

iframe {
    width: 100%;
    border: none;
    pointer-events: auto;
}

.iframe-h {
    height: 500px !important;
}

.iframe-h-2 {
    height: 600px !important;
}

.map iframe {
    border: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-filter: brightness( 100% ) contrast( 100% ) saturate( 50% ) blur( 0px ) hue-rotate( 0deg );
    -moz-filter: brightness( 100% ) contrast( 100% ) saturate( 50% ) blur( 0px ) hue-rotate( 0deg );
    -ms-filter: brightness( 100% ) contrast( 100% ) saturate( 50% ) blur( 0px ) hue-rotate( 0deg );
    -o-filter: brightness( 100% ) contrast( 100% ) saturate( 50% ) blur( 0px ) hue-rotate( 0deg );
    filter: brightness( 100% ) contrast( 100% ) saturate( 50% ) blur( 0px ) hue-rotate( 0deg );
}

.help-block.with-errors {
    position: absolute;
    color: red;
    right: 15px;
    font-size: 10px;
    line-height: 14px;
}

.form-control::-webkit-input-placeholder {
    color: var(--themeht-body-color);
}

.form-control::-moz-placeholder {
    color: var(--themeht-body-color);
}

.form-control:-ms-input-placeholder {
    color: var(--themeht-body-color);
}

.form-control:-moz-placeholder {
    color: var(--themeht-body-color);
}

/* ------------------------
    Video
------------------------*/
.video-btn {
    position: relative;
    display: inline-block;
}

.video-btn-pos {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    z-index: 99;
}

.video-btn.is-play {
    background-color: var(--themeht-secondary2-color);
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

.video-btn.is-play .video-btn-outer-circle {
    background: var(--themeht-secondary2-color);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.video-btn.is-play .video-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
}

.video-btn.is-play .video-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    color: white;
    font-size: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.has-scale-animation {
    animation: smallScale 3s infinite;
}

.has-delay-short {
    animation-delay: 0.5s;
}

/* ------------------------
    List Icon
------------------------*/
.list-icon.style-1 li {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    background: var(--themeht-white-color);
    box-shadow: 0px 25px 70px rgba(8, 10, 55, 0.08);
    padding: 10px 18px;
    border-radius: 100px;
    align-items: center;
}

.list-icon.style-1 li:last-child {
    margin-bottom: 0
}

.list-icon.style-1 li i {
    color: var(--themeht-white-color);
    font-size: 20px;
    line-height: 1;
    margin-right: 10px;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    border-radius: 50%;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-icon.style-2 li {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.list-icon.style-2 li:last-child {
    margin-bottom: 0
}

.list-icon.style-2 li i {
    color: var(--themeht-primary-color);
    font-size: 20px;
    line-height: 1;
    margin-right: 10px;
}

/* ------------------------
 Marquee Text
------------------------*/
.marquee-wrap {
    width: 100%;
    position: relative;
}

.marquee-wrap {
    overflow: hidden;
    display: block;
}

.marquee-text {
    animation: marquee 60s linear infinite;
    position: relative;
    width: 100%;
    white-space: nowrap;
}

.marquee-text span {
    font-size: 100px;
    line-height: 110px;
    color: var(--themeht-text-color);
    font-family: var(--themeht-secondary-font-family);
    font-weight: 500;
}

.marquee-text i {
    font-size: 10px;
    display: inline-block;
    line-height: 1;
    margin: 0 30px;
    color: var(--themeht-text-color);
}

.marquee-wrap.style-2 {
    padding: 30px 0;
    transform: scale(1.1) rotate(5deg);
}

.marquee-wrap.style-2 .marquee-text span, .marquee-wrap.style-2 .marquee-text i {
    color: var(--themeht-white-color);
}

/* ------------------------
 Shape & Animation
------------------------*/
#particles-js {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.ht-bg-move {
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: -1;
}

.ht-first-letter::first-letter {
    padding: 8px 0;
    font-size: 60px;
    line-height: 60px;
    display: inline-block;
    /* float: left; */
    font-weight: 600;
    margin-right: 8px;
    color: var(--themeht-primary-color);
    font-family: var(--themeht-secondary-font-family);
}

.rocket-shade {
    animation-name: MoveCircleSmall;
    animation-timing-function: linear;
    animation-duration: 5s;
    animation-delay: 0s;
    left: 0%;
    top: -50%;
    position: absolute;
    animation-iteration-count: infinite;
}

.hero-shape1 {
    z-index: -1;
    position: absolute;
    width: 110%;
    height: 100%;
    border-bottom-left-radius: 200px;
    transform: rotate(-15deg);
    top: -25%;
    right: -10%;
    background: linear-gradient(130deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
}

.wave-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: -1;
}

.wave-shape.top {
    bottom: inherit;
    top: 0;
}

.banner-shape {
    position: absolute;
    right: -30%;
    top: -30%;
    width: 80%;
    height: 100%;
    z-index: -1;
}

.banner-shape2 {
    position: absolute;
    left: 0;
    top: 8%;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.banner-shape2 img {
    animation: 30s linear infinite running rotate;
}

.banner-shape3 {
    position: absolute;
    right: -5%;
    top: -8%;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.bg-effect {
    position: relative;
}

.bg-effect::after {
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    top: 0;
    content: "";
    left: -10%;
    position: absolute;
    z-index: -1;
    height: 100%;
    border-top-right-radius: 100px;
    width: 60%;
    border-bottom-right-radius: 100px;
    transform: rotate(10deg);
}

.shape-img-bg {
    position: relative;
}

.stop-color1 {
    stop-color: var(--themeht-secondary-color);
}

.stop-color2 {
    stop-color: var(--themeht-primary-color);
}

#imagewave2 .stop-color1 {
    stop-color: var(--themeht-secondary2-color);
}

#imagewave2 .stop-color2 {
    stop-color: var(--themeht-primary2-color);
}
.step-item.style-1 .step-icon::before {
    background: linear-gradient(115deg, #c146f7, #fe9e30);
    transition: all 0.35s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: -1;
}
.step-item.style-1 .step-icon::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    height: 16px;
    width: 16px;
    background: #ffffff;
    z-index: -1;
    border-radius: 50%;
    animation: spinnerRotate 10s linear infinite;
    margin-left: -8px;
    margin-top: -8px;
    border: 2px solid #c146f7;
    transition: all 0.35s ease-in-out;
}
@keyframes spinnerRotate {
    0% {
        -webkit-transform: rotate(0deg) translateX(57px);
        transform: rotate(0deg) translateX(57px);
    }

    100% {
        -webkit-transform: rotate(360deg) translateX(57px);
        transform: rotate(360deg) translateX(57px);
    }
}
a, i, .themeht-btn, button, img, span, .nav-link, .dropdown-item, #ht-main-nav, .social-icons li:hover a, .testimonial-carousel .controls li, .service-item, .service-item.style-1 svg, .service-item.style-1::before, .service-item.style-1:after, .service-item.style-2:after, .service-item.style-3::before, .service-item.style-3:after, .clients-logo, .service-item.style-3, .featured-item, .featured-icon, .featured-item .featured-icon i, .portfolio-item, .price-table, .post .post-desc, .service-menu li a:before, .team-social-icon, .step-icon-bg, .accordion .accordion-button:before, .accordion .accordion-button:after {
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}
.product-img {
    position: relative;
    overflow: hidden;
}

.product-img img {
    border-radius: 20px
}

.product-link a i {
    display: inline-block;
    color: var(--themeht-white-color);
    font-size: 18px;
}

.product-link a {
    border-radius: 50px;
    padding: 5px 20px;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    font-weight: 500;
    display: inline-block;
    color: var(--themeht-white-color);
    position: relative;
    text-align: center;
}

.product-link a:hover {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    color: var(--themeht-white-color);
}

.product-name {
    display: inline-block;
    color: var(--themeht-text-color);
    margin-top: 30px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500
}

.product-price {
    display: block;
}

.form-check-input:checked {
    background-color: var(--themeht-primary-color);
    border-color: var(--themeht-primary-color);
}

.widget-color [type="radio"]:checked, .widget-color [type="radio"]:not(:checked) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.widget-color .form-check-label {
    width: 26px;
    height: 26px !important;
    border-radius: 0.25rem;
    padding: 0 !important;
    margin-bottom: 0;
}

.widget-color .form-check-input[type="radio"]:checked + label, .widget-color .form-check-input[type="radio"]:not(:checked) + label, label.btn input[type="radio"]:checked + label, label.btn input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 1.5625rem;
    line-height: 1.5625rem;
    transition: .28s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.widget-color .form-check-input[type="radio"] + label::after, .widget-color .form-check-input[type="radio"] + label::before, label.btn input[type="radio"] + label::after, label.btn input[type="radio"] + label::before {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: 30px;
    height: 30px;
    z-index: 0;
    transition: .28s ease;
    z-index: -1;
    border-radius: 0.25rem;
}

.widget-color .form-check-input[type="radio"]:checked + label::after, label.btn input[type="radio"]:checked + label::after {
    transform: scale(1.02);
}

.widget-color .form-check-input[type="radio"]:checked + label::after {
    background-color: var(--themeht-primary-color);
}

.widget-size input[type="radio"] {
    visibility: hidden;
    position: absolute;
}

.widget-size label {
    background: var(--bg-light);
    margin-bottom: 0;
    display: inline-block;
    height: 40px;
    min-width: 40px;
    text-align: center;
    font-size: 14px;
    color: var(--themeht-text-color);
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.25rem;
    line-height: 40px;
}

.widget-size input[type="radio"]:checked + label {
    background: var(--themeht-primary-color);
    color: var(--themeht-white-color);
}

.widget-color li {
    list-style-type: none;
    display: inline-block;
    margin-right: 1%;
    margin-bottom: 20px;
    width: 22%;
    text-align: center;
}

.widget-color li:nth-child(4n+4) {
    margin-right: 0;
}

.widget-size li {
    list-style-type: none;
    display: inline-block;
    width: 20%;
    text-align: center;
}

.widget-size li:nth-child(4n+4) {
    margin-right: 0;
}

input.form-product {
    height: 40px !important;
    border: none;
    background: var(--themeht-white-color);
    text-align: center;
    width: 150px;
    border-top: 1px solid var(--themeht-border-light-color) !important;
    border-bottom: 1px solid var(--themeht-border-light-color) !important;
    color: var(--themeht-text-color);
    vertical-align: middle;
}

.btn-product {
    background: none;
    color: var(--themeht-text-color);
    border: 1px solid var(--themeht-border-light-color);
    height: 40px;
    width: auto;
    padding: 0 10px;
    font-size: 13px;
    cursor: pointer;
}

.cart-table td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    color: var(--themeht-text-color);
}

.step-item.style-1 {
    text-align: center;
    padding: 0 30px
}

.step-item.style-1 .step-icon {
    display: block;
    height: 120px;
    width: 120px;
    text-align: center;
    line-height: 120px;
    color: var(--themeht-white-color);
    border-radius: 50%;
    font-size: 50px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    transition: all 0.35s ease-in-out;
    margin-bottom: 30px;
}

.step-item.style-1 .step-icon::before {
    background: linear-gradient(115deg, #c146f7, #fe9e30);
    transition: all 0.35s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: -1;
}

.step-item.style-1 .step-icon::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    height: 16px;
    width: 16px;
    background: #ffffff;
    z-index: -1;
    border-radius: 50%;
    animation: spinnerRotate 10s linear infinite;
    margin-left: -8px;
    margin-top: -8px;
    border: 2px solid #c146f7;
    transition: all 0.35s ease-in-out;
}

.step-item.style-1 h4 {
    margin-bottom: 10px;
}

.text-white.step-item.style-1 h4 {
    color: var(--themeht-white-color);
}

.step-item.style-1 span {
    font-family: var(--themeht-body-font-family);
    font-weight: 500;
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    display: inline-block;
    padding: 10px 20px;
    border-radius: 100px;
    color: var(--themeht-white-color);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    margin-top: 20px;
}

.step-bg.step-item.style-1 .step-icon::before {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
}

.step-bg.step-item.style-1 span {
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
}

.step-item.style-2 {
    text-align: center;
}

.step-item.style-2 .step-icon {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 153px;
    line-height: 153px;
    text-align: center;
    font-size: 60px;
    margin-bottom: 24px;
    transition: all 500ms ease;
    color: var(--themeht-primary-color);
    margin-top: 8px;
}

.step-bg.step-item.style-2 .step-icon {
    color: var(--themeht-primary2-color);
}

.step-item.style-2 .step-icon i {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.step-item.style-2 .step-icon .step-icon-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    width: 180px;
    height: 153px;
    background-repeat: no-repeat;
}

.step-item.style-2:hover .step-icon .step-icon-bg {
    transform: rotate(10deg);
}

.step-item.style-2 .step-icon::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    box-shadow: 0 0px 100px 30px rgba(0, 0, 0, 0.5);
    transition: all 500ms ease;
}

.step-item.style-2 .step-icon .step-number {
    position: absolute;
    top: -8px;
    right: -5px;
    font-size: 15px;
    font-weight: 700;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    color: var(--themeht-white-color);
    transition: all 500ms ease;
}

.step-bg.step-item.style-2 .step-icon .step-number {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
}

.step-item.style-2.left-side {
    text-align: left;
    display: flex;
    align-items: center;
}

.step-item.style-2.left-side .step-icon {
    margin-bottom: 0;
    margin-right: 40px;
    flex-shrink: 0;
}

.step-process-area {
    position: relative;
}

.step-process-area::after {
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 48.44%, hsla(0, 0%, 100%, .152) 99.99%, hsla(0, 0%, 100%, 0));
    top: 180px;
    content: "";
    height: 1px;
    left: 10%;
    right: 10%;
    opacity: .2;
    position: absolute;
}

.step-process-area h4 {
    text-align: center;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.125rem;
}

.step-process-area p {
    text-align: center;
    color: #fff;
}

.MB40 {
    margin-bottom: 40px;
}

.fz40 {
    font-size: 40px !important;
}

.bg {
    background: #eaeaeb;
}

.MB50 {
    margin-bottom: 50px;
}

.xs {
    display: none !important;
}

.step-icon img {
    display: block;
    height: 120px;
    width: 120px;
    text-align: center;
    line-height: 120px;
    color: var(--themeht-white-color);
    border-radius: 50%;
    font-size: 50px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    transition: all 0.35s ease-in-out;
    margin-bottom: 30px;
}

.abt h4 {
    margin-top: 20px;
}

.abt p {
    margin-top: 0px !important;
}

.service-item.style-2 {
    padding: 20px !important;
}

.pb0 {
    padding-bottom: 0 !important;
}


.bbps {
    padding-top: 50px;
    padding-bottom: 50px;
}

.partner {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pt0 {
    padding-top: 0 !important;
}

.bbps h2 {
    margin-bottom: 10px;
}

.bbps p {
    text-align: justify;
}

.partner img {
    width: 100%;
    border: 1px solid #aaa;
    margin-bottom: 30px;
}

.terms p {
    margin-bottom: 10px !important;
}
.dropdown-toggle::after {
    display: none !important; /* Hides the arrow */
  }
  
.terms h4 {
    font-size: 18px !important;
}

.mask-img1 {
    width: 100%;
}
.page-title{
    margin-top: 41px !important;
}
@media (max-width: 767px) and (min-width:320px) {
    .banner {
        padding-bottom: 20px !important;
    }

    .xs {
        display: block !important;
    }

    .navbar-nav .nav-link {
        padding: 5px 0 !important;
    }

    .page-title {
        padding: 75px 0 !important;
        padding-bottom: 0 !important;
    }

    .theme-title h2 {
        font-size: 18px !important;
        line-height: 25px !important;
    }

    .partner .col-lg-3 {
        width: 50%;
    }

    .partner img {
        margin-bottom: 0px;
    }

    .partner {
        padding-top: 0;
        padding-bottom: 50px !important;
    }
}

.ht-bg-move {
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: -1;
}
.dark-bg {
    background-color: #000229;
}
.theme-title h2 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
}
p {
    line-height: 1.7;
    font-weight: 300;
    font-size: 0.875rem;
}
.mask-img {
    -webkit-mask-image: url(../../../public/assets/Home/download.png);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    mask-image: url(../../../public/assets/Home/download.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 100%;
    height: 582px;
    object-fit: cover;
    position: relative;
    z-index: 1;
}
@media (min-width: 992px) {
    .ps-lg-10, .px-lg-10 {
        padding-left: 4.5rem !important;
    }
}
.img-bg-shape {
    position: absolute;
    left: -10%;
    top: -20%;
    width: 120%;
    height: 100%;
    z-index: 0;
}
@media (max-width: 992px) {
    .img-bg-shape {
        width: 110%;
    }
}
.theme-title h6 {
    line-height: 1;
    padding: 0 15px;
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 9;
}

.theme-title h6:before, .theme-title h6:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    width: 6px;
    height: 6px;
    border-radius: 50%;
    z-index: -1;
}

.theme-title h6:before {
    left: auto;
    right: 0;
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
}

.theme-title h2 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
}


.theme-title p {
    margin-bottom: 0;
    margin-top: 15px
}

.theme-bg .theme-title h6, .text-white.theme-title h6 {
    background: linear-gradient(115deg, var(--themeht-white-color), var(--themeht-white-color), var(--themeht-white-color));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    z-index: 9;
    background: var(--themeht-white-color);
}

.featured-item.style-1 .featured-icon {
    display: inline-block;
    position: relative;
    margin-bottom: 30px
}

.featured-desc p {
    margin-bottom: 0;
}

.featured-title h4 {
    margin-bottom: 15px;
}

.featured-item.style-1 {
    padding: 40px;
}

.featured-item.style-1 .featured-icon i {
    background: linear-gradient(115deg, var(--themeht-primary-color), var(--themeht-secondary-color));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
    font-size: 70px;
}

.featured-item.style-2 {
    padding: 30px;
}

.featured-item.style-2 .featured-icon {
    width: 30%;
    margin-right: 20px;
}

.featured-item-top {
    margin-bottom: 20px
}

.featured-item-top h4 {
    margin-bottom: 0
}

.featured-item.style-3 {
    padding: 30px;
    background: var(--themeht-white-color);
    overflow: inherit;
    padding-left: 50px;
}

.featured-item.style-3.text-end {
    padding-left: 30px;
    padding-right: 50px;
}

.featured-item.style-3 .featured-icon {
    display: inline-block;
    border-radius: 50%;
    color: var(--themeht-white-color);
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    left: -35px;
}

.featured-item.style-3.text-end .featured-icon {
    left: inherit;
    right: -35px;
}
@media (min-width: 992px) {
    .ms-lg-7, .mx-lg-7 {
        margin-left: 3rem !important;
    }
}

/* ------------------------
    Service Item
------------------------*/
.service-item.style-1 {
    background: #eaeaea;
    padding: 40px 30px;
    padding-top: 0;
    position: relative;
    z-index: 9;
    border-radius: 20px;
    margin-top: 60px;
    box-shadow: 0px 25px 70px rgba(8, 10, 55, 0.08);
}

.service-item.style-1 .service-icon {
    display: inline-block;
    height: 120px;
    border: 1px solid #eee;
    width: 120px;
    text-align: center;
    line-height: 120px;
    color: var(--themeht-primary-color);
    border-radius: 50%;
    font-size: 50px;
    position: relative;
    z-index: 1;
    transition: all 0.35s ease-in-out;
    margin-bottom: 30px;
    margin-top: -60px;
}

.service-item.style-1 .service-icon::before {
    background: linear-gradient(115deg, #fff, #fff);
    transition: all 0.35s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.1;
}

.service-item.style-1:hover .service-icon::before, .service-active.service-item.style-1 .service-icon::before {
    opacity: 1;
}

.service-item.style-1:hover .service-icon, .service-active.service-item.style-1 .service-icon {
    opacity: 1;
    color: var(--themeht-white-color);
}

.service-item.style-1 .service-icon::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    height: 16px;
    width: 16px;
    background: linear-gradient(115deg, #fbece2, #fbece2);
    z-index: -1;
    border-radius: 50%;
    animation: spinnerRotate 10s linear infinite;
    margin-left: -8px;
    margin-top: -8px;
    border: 2px solid var(--themeht-primary-color);
    transition: all 0.35s ease-in-out;
}

.service-item.style-1 p {
    margin-bottom: 20px
}

.service-item.style-1:hover h4, .service-item.style-1:hover p, .service-item.style-1:hover, .service-active.service-item.style-1 h4, .service-active.service-item.style-1 p, .service-active.service-item.style-1 {
    color: var(--themeht-white-color) !important;
}

.service-item.style-1::before {
    border-radius: 20px;
    transform: scale(0);
    background: var(--themeht-bg-dark-color);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
}

.service-item.style-1:hover::before, .service-active.service-item.style-1::before {
    transform: scale(1);
}

.service-item.style-1:after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    opacity: 0.04;
    width: 100%;
    z-index: -1;
    background: url(../../../public/assets/Home/02.png) no-repeat 0 0;
    background-size: cover;
}

.service-item.style-1:hover:after, .service-active.service-item.style-1:after {
    opacity: 0.2;
}

.service-item.style-1 .arrow-btn {
    margin-left: auto;
}

.service-item.style-2 {
    text-align: left;
    position: relative;
    z-index: 9;
    padding: 40px 40px 70px;
    background: #f2f2f2;
    border-radius: 20px;
    margin-bottom: 25px
}

.service-item.style-2:hover {
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15);
    transform: translate3d(0, -20px, 0);
}

.service-item.style-2 .service-icon {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 0px
}

.service-item.style-2 .service-desc p {
    margin: 20px 0 0
}

.service-item.style-2 h4 {
    margin-bottom: 0
}

.service-item.style-2 .arrow-btn {
    position: absolute;
    bottom: -25px;
    left: 50%;
    margin-left: -25px;
}
.arrow-btn {
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    display: inline-block;
    color: var(--themeht-white-color);
    z-index: 500;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
}
.zoom-fade, .service-item.style-1:after, .service-item.style-2:after {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

}
.service-item.style-2:after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    opacity: 0.04;
    width: 100%;
    z-index: -1;
    background: url(../../../public/assets/Home/02.png) no-repeat 0 0;
    background-size: cover;
}

@keyframes zoom-fade {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
.service-item.style-2:hover:after, .service-active.service-item.style-1:after {
    opacity: 0.1;
}

.swiper-slide .service-item.style-2 {
    margin: 50px 15px;
    padding: 40px 30px 70px;
    box-shadow: 0px 5px 20px rgba(8, 10, 55, 0.08);
}

.service-item.style-3 {
    overflow: hidden;
    text-align: center;
    position: relative;
    z-index: 9;
    padding: 60px 40px;
    background: var(--themeht-white-color);
    border-radius: 75px 0 75px 0;
}

.service-item.style-3 .service-icon {
    display: inline-block;
    margin-bottom: 20px
}

.service-item.style-3 .service-icon i {
    color: var(--themeht-primary-color);
    line-height: 1;
    font-size: 70px;
    display: inline-block;
}

.service-item.style-3 p {
    margin-bottom: 30px;
}

.service-item.style-3:hover {
    border-radius: 20px 90px 20px 90px;
}

.service-item.style-3::before {
    border-radius: 20px;
    transform: scale(0);
    background: linear-gradient(115deg, var(--themeht-primary2-color), var(--themeht-secondary2-color));
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
}

.service-item.style-3:hover::before, .service-active.service-item.style-3::before {
    transform: scale(1);
}

.service-item.style-3:after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    opacity: 0.04;
    width: 100%;
    z-index: -1;
    background: url(../../../public/assets/Home/02.png) no-repeat 0 0;
    background-size: cover;
}

.service-item.style-3:hover:after, .service-active.service-item.style-3:after {
    opacity: 0.2;
}

.service-item.style-3:hover h4, .service-item.style-3:hover p, .service-item.style-3:hover, .service-active.service-item.style-3 h4, .service-active.service-item.style-3 p, .service-active.service-item.style-3 {
    color: var(--themeht-white-color)
}

/*Service Widget*/
.service-widget {
    box-shadow: 0 10px 30px 5px rgba(115, 113, 255, .06);
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 50px;
    background: var(--themeht-white-color);
}

.service-widget:last-child {
    margin-bottom: 0
}

.service-menu li {
    border-bottom: 1px dashed var(--themeht-border-light-color);
    padding-bottom: 15px;
    margin-bottom: 15px
}

.service-menu li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0
}

.service-menu li a {
    color: var(--themeht-text-color);
    display: block;
    font-size: 16px;
    position: relative;
}

.service-menu li a:hover {
    color: var(--themeht-primary-color);
}

.service-menu li a:before {
    position: absolute;
    top: 0;
    right: 0;
    font-family: bootstrap-icons !important;
    content: "\F135";
    font-size: 22px;
    display: flex;
    z-index: 99;
    height: 100%;
    color: var(--themeht-primary-color);
    justify-content: center;
    align-items: center;
}

.service-menu li a:hover:before {
    right: 10px;
}

.contact-widget {
    padding: 30px;
}
.marginTop{
    margin-top: 118px;
}
/* ------------------------ 404 Page ------------------------ */
.Image404{
    width: 100%;
    margin-top: 110px;
    display: flex;
}
.Image404 img{
    background-attachment: fixed;
    margin: 0 auto;
}