.custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded edges for the thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* Darker color on hover */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the scrollbar track */
  }


  .sliding-panel {
    position: fixed;
    right: -100%;
    top: 0;
    width: 300px;
    height: 100%;
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out;
    z-index: 1050;
  }
  .sliding-panel.open {
    right: 0;
  }
  .sliding-panel-content {
    padding: 20px;
  }
  .close-button {
    cursor: pointer;
  }
  
  