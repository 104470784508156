.page-title {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .full-width-image {
    width: 100vw;
    height: auto;
    display: block;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .bbps {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pb0 {
    padding-bottom: 0 !important;
}
.shape-img-bg {
    position: relative;
}

.mask-img1 {
    width: 80%;
}

img {
    vertical-align: middle;
    transition: all 0.5s ease-in-out 0s;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.bbps h2 {
    margin-bottom: 10px;
}

.bbps p {
    text-align: justify;
}

p {
    line-height: 1.7;
}