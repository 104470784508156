.partner {
    padding-top: 50px;
    padding-bottom: 50px;
}
.partner img {
    width: 100%;
    border: 1px solid #aaa;
    margin-bottom: 30px;
}
img {
    vertical-align: middle;
    transition: all 0.5s ease-in-out 0s;
    overflow-clip-margin: content-box;
    overflow: clip;
}